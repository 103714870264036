.page.investmentmodel {
  

  //investment flatform
  .investment_flatform {
    @include webmain_spacing-topmain;
    .investment_flatform__head {
      text-align: center;
      h2 {
        @include webmain_fontSize_title;
        color: $color_KC_darkBlue;
        padding-bottom: 60px;
        @include respond-mobile() {
          padding-bottom: 7vw;
        }
      }

      p {
        @include webmain_text-small;
        color: $color_KC_darkBlue;
        max-width: 860px;
        width: 95%;
        margin: 0 auto;
        padding-bottom: 30px;
        @include respond-mobile() {
          padding-bottom: 3vw;
        }
      }
    }

    .investment_flatform_img {
      text-align: center;

      img {
        width: 455px;
        height: 436px;
        margin: 50px auto;

        @include respond-mobile() {
          width: 75%;
          height: auto;
          margin: 5vw auto 10vw;
        }
      }
    }

    .team-investment {
      color: $color_KC_darkBlue;
      p {
        @include webmain_text-small;
        @include webmain_font-regular;
        text-align: center;
        padding-bottom: 30px;

        @include respond-tabletportrait() {
          padding: 20px;
        }
        @include respond-mobile() {
          padding: 3vw;
        }

        span {
          @include webmain_font-bold;
          display: block;
        }
      }
    }

    .investment_flatform_content {
      @include webmain_spacing-bottommain;
      background-color: $color_KC_gray;
      padding: 70px;
      @include respond-desktopsmall() {
        padding: 50px;
      }

      @include respond-tabletlandscape() {
        padding: 40px;
      }

      @include respond-tabletportrait() {
        padding: 20px;
      }
      @include respond-mobile() {
        padding: 0 7vw;
      }

      

      .investment_flatform_block .inner {
        border-right: 1px solid $color_KC_darkBlue;
        min-height: 210px;

        @include respond-desktopsmall() {
          min-height: 230px;
        }

        @include respond-tabletlandscape() {
          min-height: 150px;
          border: none;
        }

        @include respond-tabletportrait() {
          min-height: auto;
          padding-bottom: 20px;
        }
        @include respond-mobile() {
          min-height: auto;
          padding: 0;
        }

        .investment_flatform_block_text {
          @include webmain_text-small;
          @include webmain_font-regular;
          color: $color_KC_darkBlue;
          padding-right: 25px;

          @include respond-tabletportrait() {
            padding: 5vw 5vw 5vw 0;
          }
          @include respond-mobile() {
            padding: 5vw 5vw 5vw 0;
          }
          span {
            @include webmain_font-bold;
            display: block;
          }
        }

        &.border_top_landscape{

          @include respond-tabletlandscape() {
            border-top: 1px solid $color_KC_darkBlue;
            padding-top: 30px;
          }

          @include respond-tabletportrait() {
           border: none;
           padding-top: 0;
           }
           @include respond-mobile() {
            border: none;
            padding-top: 0;
           }

        }
      }

      .investment_flatform_block:last-child .inner {
        border-right: none;
      }

      .border_top {

       
        @include respond-tabletportrait() {
          border-top: 1px solid $color_KC_darkBlue;
        }

        @include respond-mobile() {
          border-top: 1px solid $color_KC_darkBlue;
        }
        
      }

      
    }
  }

  //scc for governance
  .governance {
    .governance__head {
      text-align: center;
      h2 {
        @include webmain_fontSize_title;
        color: $color_KC_darkBlue;
        padding-bottom: 60px;
        @include respond-mobile() {
          padding-bottom: 7vw;
        }
      }

      p {
        @include webmain_text-small;
        color: $color_KC_darkBlue;
        max-width: 1030px;
        width: 95%;
        margin: 0 auto;
        padding-bottom: 30px;
        @include respond-mobile() {
          padding-bottom: 3vw;
        }
      }
    }

    .governance__content {
      max-width: 1200px;
      width: 95%;
      margin: 80px auto;
      color: $color_KC_darkBlue;
      @include respond-mobile() {
        margin: 5vw auto 10vw;
      }

      .block {
        margin-bottom: 5vw;

        .inner .block__num {
          line-height: 0.8;
          margin-right: 45px;
          width: 25px;

          @include respond-mobile() {
            margin-right: 8vw;
            width: 3.7vw;
          }
        }
      }
    }
  }
}


body.IE {

  .page.investmentmodel .governance .governance__content .Block .inner .block__num {
    margin-right: 90px;
  }
}