.cookie {
    @include webmain_font_regular2;
    @include webmain_text-small;
    background-color: $color_blue;
    color: #fff;
    position: fixed;
    width: 100%;
    z-index: 9999;
    display: none;
    max-width: 830px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include respond-mobile {
        height: 100vh;
    }

    @media (max-height: 600px) {
        overflow-y: scroll;
        height: 100%;
        max-height: 100%;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background: #fff;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #21043c;
        }
    }

    &.active {
        display: block;
    }

    // font-size //
    span,
    .group,
    .cta {
        // @include qsrp_text-normal;
        // @include qsrp_font-regular;
    }

    a {
        color: #fff;
        text-decoration: underline;
    }

    .inner {
        padding: 45px;

        @include respond-tabletlandscape {
            flex-flow: column;
            padding: 35px;
        }

        @include respond-tabletlandscape {
            padding: 15px 5px;
        }

        @include respond-mobile {
            padding: 5vw;
            @include objAbsoluteMiddle();
            width: 100%;
        }
    }

    .group {
        margin: 25px;

        // group-title //
        &-title {
            margin: 50px;

            @include respond-mobile {
                margin: 5vw;
            }
        }

        // group-switch //
        &-switch {
            @include respond-mobile {
                margin: 0;
            }

            .switch-item {
                padding: 25px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                div:first-child {
                    padding-right: 2vw;
                }

                @include respond-mobile {
                    padding: 3vw 5vw 3vw 5vw;
                }

                &.active-none {
                    background: #061927;
                    border-radius: 10px;
                }

                // custom - button - switch //
                .switch {
                    position: relative;
                    display: inline-block;
                    width: 65px;
                    height: 34px;

                    @include respond-mobile {
                        width: 15vw;
                        height: 7vw;
                    }

                    input:checked+.slider {
                        background-color: $color_KC_Orange;

                        &:before {
                            -webkit-transform: translateX(25px);
                            -ms-transform: translateX(25px);
                            transform: translateX(25px);

                            @include respond-mobile {
                                -webkit-transform: translateX(7vw);
                                -ms-transform: translateX(7vw);
                                transform: translateX(7vw);
                            }
                        }
                    }

                    input:disabled+.slider {
                        opacity: 0.5;
                    }

                    input {
                        opacity: 0;
                        width: 0;
                        height: 0;
                    }

                    .slider {
                        position: absolute;
                        cursor: pointer;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        border-radius: 17px;
                        background-color: $color_KC_darkPurple;
                        -webkit-transition: .4s;
                        transition: .4s;

                        &:before {
                            position: absolute;
                            content: "";
                            height: 41px;
                            width: 41px;
                            left: 0;
                            bottom: -4px;
                            background-color: #fff;
                            -webkit-transition: .4s;
                            transition: .4s;
                            border-radius: 50%;

                            @include respond-mobile {
                                height: 8vw;
                                width: 8vw;
                                bottom: -0.5vw;
                            }
                        }
                    }
                }
            }
        }

        // group-cta //
        &-cta {
            display: flex;
            align-items: center;
            justify-content: center;

            @include respond-tabletlandscape {
                display: flex;
                margin-top: 15px;
            }

            @include respond-tabletportrait() {
                flex-direction: column;
                flex-wrap: wrap;
            }

            @include respond-mobile {
                flex-direction: column;
                flex-wrap: wrap;
            }

            .cta {
                @include webmain_font_regular2;
                background-color: $color_blue;
                width: 50%;
                text-align: center;
                padding: 15px;

                @include respond-tabletportrait() {
                    width: 100%;
                    text-align: center;
                }

                @include respond-mobile {
                    width: 100%;
                }

                &:first-child {
                    border: 2px solid #fff;
                    border-radius: 50px;
                    @include webmain_font_regular2;
                    @include webmain_text-normal;
                    width: 35%;

                    @include respond-tabletportrait() {
                        width: 40%;
                        margin: auto;
                        text-align: center;
                        margin-bottom: 5vw;
                        padding: 10px;
                    }

                    @include respond-mobile {
                        width: 70%;
                        margin: auto;
                        text-align: center;
                        margin-bottom: 5vw;
                        padding: 3vw;
                    }

                    &:hover {
                        border: 2px solid $color_KC_Orange;
                        color: $color_KC_Orange;
                    }
                }

                &:hover {
                    display: block;
                }
            }

            a.cta {
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                    color: $color_KC_Orange;
                }
            }
        }
    }
}
