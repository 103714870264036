*:focus {
  outline: none;
  box-shadow: none;
}

body.customscrolling {
  overflow: hidden;

  
  main {
    height: 100vh;
    
    @include respond-mobile {
      @include fullheightVH();
    }
  }

}

.scrollbar-thumb {
  border-radius: 0;
}

@media (min-width: 1400px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1250px;
  }
}

@media (min-width: 1600px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1550px;
  }
}
