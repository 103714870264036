// Defining values
$para_break_1: 540px;
$para_break_2: 768px;
$para_break_3: 992px;
$para_break_4: 1600px;


@mixin breakpoint($class) {
    @if $class==xs {
        @media (max-width: $para_break_2) {
            @content;
        }
    }

    @else if $class==sm {
        @media (min-width: $para_break_2) {
            @content;
        }
    }

    @else if $class==md {
        @media (min-width: $para_break_3) {
            @content;
        }
    }

    @else if $class==mmd {
        @media (max-width: $para_break_3) {
            @content;
        }
    }

    @else if $class==lg {
        @media (min-width: $para_break_4) {
            @content;
        }
    }

    @else if $class==lgg {
        @media (max-width: $para_break_4) {
            @content;
        }
    }

    @else if $class==Mobile {
        @media (max-width: $para_break_1) {
            body {
                @content;
            }
        }

        .Mobile {
            @content;
        }
    }

    @else {
        @warn "Breakpoint mixin supports: xs, sm, md, lg";
    }
}




/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
$breakpoints: ('dt': (min-width: $para_break_1),
    'mb': (max-width: $para_break_1),
    'xs': (max-width: $para_break_2),
    'sm': (min-width: $para_break_2),
    'md': (min-width: $para_break_3),
    'mmd': (max-width: $para_break_3),
    'lg': (min-width: $para_break_4),
    'lgg': (max-width: $para_break_4)) !default;

@mixin respond-to($breakpoint) {

    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {

        // Prints a media query based on the value
        @media #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    }

    // If the key doesn't exist in the map
    // @else {
    //     @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    //     +"Available breakpoints are: #{map-keys($breakpoints)}.";
    // }
}

@mixin respond-mobile() {

    // < 540
    @include respond-to('mb') {
        @content;
    }

    .Mobile & {
        @content;
    }
}


@mixin respond-landscapemobile() {

    // < 540
    @media only screen and (orientation: landscape) {
        .Mobile & {
            @content;
        }
    }
}


@mixin respond-tabletportrait() {

    // < 768
    @include respond-to('xs') {
        @content;
    }
}

@mixin respond-tabletlandscape() {
    // < 992

    @include respond-to('mmd') {
        @content;
    }
}

@mixin respond-desktopsmall() {
    // < 1600

    @include respond-to('lgg') {
        @content;
    }
}

@mixin respond-desktopbig() {
    // > 1600

    @include respond-to('lg') {
        @content;
    }
}

@mixin respond-desktop() {

    // .Desktop >540
    @include respond-to('dt') {
        @content;
    }

    .Desktop & {
        @content;
    }
}
