.footer {
  background: $color-blue;
  padding-top: 50px;
  position: relative;
  @include webmain_text-smallii;
  @include webmain_font-regular;

  @include respond-landscapemobile() {
    padding-top: 5vw;
    font-size: 2vh;
  }

  &-item {
    margin-bottom: 5px;
  }

  .logo {
    margin: 0 auto;
    width: 200px;
    height: 91px;

    @include respond-landscapemobile() {
      width: 30vh;
      height: 16vh;
      margin: 4vh auto 0;
    }
  }

  .title {
    @include webmain_text-smallii;
    @include webmain_font-bold;
    display: block;
    color: $color-orange;

    @include respond-landscapemobile() {
      font-size: 2.5vh;
    }
  }
  .sub-title{
    @include webmain_text-smallii;
    @include webmain_font-regular;
    font-weight: 600;
    display: block;

  }

  .d-block{
    display: block;
  }

  .no-padding{

    padding-right: 0 !important;
    padding-left: 0 !important;
    
   
  }

  .content {
    padding-top: 50px;
    //padding-bottom: 50px;

    @include respond-tabletlandscape() {
      padding-top: 30px; 
    }

    @include respond-tabletportrait() {
      padding-top: 20px; 
    }

    @include respond-mobile() {
      padding-top: 5vw; 
    }

    @include respond-landscapemobile() {
      padding-top: 0;
    }
    
    a {
      color: #fff;
      display: block;

      &:hover {
        color: #ccc;
      }
    }

    .group {
      &-1 {
        margin-bottom: 15px;
        @include respond-tabletlandscape() {
          text-align: left;

        }
        
      }
      &-2 {
        margin-bottom: 15px;

        @include respond-landscapemobile() {
          margin-bottom: 1vh;
        }
      }
      &-3 {
        margin-bottom: 15px;

        @include respond-landscapemobile() {
          margin-bottom: 1vh;
        }

        @include respond-mobile() {
          margin-bottom: 0;
        }
      }
    }
    
  }

  .legal {
    padding: 30px 0;
    text-align: center;
    background-color: $color-blue-dark;

  }
}

.section-footer .footer .legal {
  @include respond-landscapemobile(){
    margin-top: 0;
    padding: 4vh 0;
  }
}