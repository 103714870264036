section.strategicoutlook {

    // Public//
    .bg-gray {
        background-color: #e6e8ea;
    }

    .center-y {
        display: flex;
        align-items: center;
    }

    //End-Public//
    .cpvisual {
        margin-bottom: 0;

        p {
            max-width: 800px;
        }
    }

    .main {
        @include webmain_spacing-bottommain;

        .row {
            margin-left: 0;
            margin-right: 0;
            padding: 75px 110px;
            line-height: 1.3;

            @include respond-tabletlandscape() {
                .col-md-12 {
                    flex: 0 0 50%;
                }
            }

            @include respond-tabletportrait() {
                .col-md-12 {
                    flex: initial;
                }
           }
    
           

            &.block-img {
                padding-top: 0;
            }

            @include respond-desktopsmall() {
                padding: 75px 80px;

            }

            @include respond-tabletlandscape {
                padding: 75px 30px;

                &.col-reverse {
                    flex-direction: column-reverse;
                }

                &.col-column {
                    flex-direction: column;
                }
            }

            @include respond-tabletportrait {
                padding: 50px 0;

                &.col-reverse {
                    flex-direction: column-reverse;
                }
            }

            @include respond-mobile {
                padding: 10vw 3vw;

                &.col-reverse {
                    flex-direction: column-reverse;
                }
            }

            &.background-white {
                @include respond-tabletlandscape {
                    justify-content: space-between !important;
                }
            }

            .focus-subcontent {
                @include respond-tabletportrait {
                    padding-left: 60px;
                }

                @include respond-mobile {
                    padding-left: 10vw;
                }
            }
        }
    }

    .block {
        .inner {
            align-items: center;
            margin-bottom: 10px;

            // height: 50px;
            @include respond-mobile {
                padding-bottom: 0px;
            }

            .block__num {
                @include webmain_fontSize40;
                color: #c7c9cb;
                margin-bottom: 0px;
                width: 50px;

                @include respond-desktopsmall() {
                    width: 20px;
                    margin-right: 10px;
                }

                @include respond-tabletportrait {
                    width: 35px;
                }

                @include respond-mobile {
                    top: 0;
                    font-size: 8.5vw;
                }
            }
        }
    }

    .group-subtitle {
        @include respond-mobile {
            margin-top: 5vw;
        }

        span {
            @include webmain_text-small;
        }
    }

    .group-title {

        @include respond-desktopsmall() {
            padding-bottom: 20px;
        }

        @include respond-tabletportrait {
            padding-bottom: 20px;
        }

        @include respond-mobile {
            padding-bottom: 0;
        }

        span {
            @include webmain_fontSize_title;
            @include webmain_font-special;
        }

        p {
            @include webmain_text-small;
        }
    }

    .block-img {
        img {
            margin: auto;
            width: 100%;
            max-width: 660px;
            height: auto;
        }
    }

    .row-img {
        line-height: 1.3;

        
        @include respond-tabletlandscape() {
            .col-md-12 {
                flex: 0 0 50%;
            }
        }

        @include respond-tabletportrait() {
            .col-md-12 {
                flex: initial;
            }
       }

        .group-title {
            @include respond-tabletportrait {
                padding-bottom: 20px;
            }

            @include respond-mobile {
                padding-bottom: 0;
            }
        }

        &-1 {
            background: url("/images/visual/investment_model.jpg") no-repeat center;
            background-size: cover;

            .row {
                // @include webmain_spacing-topmain;

                justify-content: space-between;

                margin: auto;
                width: 90%;
                padding: 200px 35px;



                @include respond-tabletlandscape {
                    padding: 100px 0;
                    width: 100%;
                    &.col-reverse {
                        flex-direction: column-reverse;
                    }

                    &.col-column {
                        flex-direction: column;
                    }
                }

                @include respond-tabletportrait() {
                    &.col-reverse {
                        flex-direction: column-reverse;
                    }
                }

                @include respond-mobile {
                    padding: 10vw 3vw;

                    &.col-reverse {
                        flex-direction: column-reverse;
                    }

                }
            }
        }

        &-2 {
            position: relative;
            height: 100%;
            max-height: 600px;
            @include webmain_spacing-topmain;

            @include respond-desktopsmall() {
                max-height: 500px;
            }

            @include respond-tabletlandscape {
                max-height: 440px;
            }

            @include respond-mobile {
                max-height: 88vw;
                margin-bottom: 0px;
                margin-top: 0;
            }

            @include respond-landscapemobile() {
                height: 85vw;
            }

            img {
                position: absolute;
                top: 0px;
                right: 0;
                height: auto;
                width: 100%;
                max-width: 570px;

                @include respond-desktopsmall() {
                    max-width: 505px;
                }

                @include respond-tabletlandscape {
                    max-width: 422px;
                }

                @include respond-mobile {
                    margin-top: 0;
                    max-width: 50vw;
                    top: 35vw;
                }
            }

            .row {
                padding: 75px 110px;

                @include respond-desktopsmall() {
                    padding: 75px 80px;
                }

                @include respond-tabletlandscape {
                    padding: 75px 30px;

                    &.col-column {
                        flex-direction: column;
                    }
                }

                @include respond-tabletportrait {
                    padding: 50px 0;
                }

                @include respond-mobile {
                    padding: 10vw 8vw;
                }

                @include respond-landscapemobile() {
                    padding: 10vw 5vw;
                }

                p {
                    padding-top: 30px;

                    @include respond-tabletlandscape {
                        padding-top: 20px;
                    }

                    @include respond-mobile {
                        padding-bottom: 2vw;
                    }
                }
            }
        }
    }
}
