@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Work+Sans:wght@700&display=swap');

@font-face {
    font-family: 'AXIS Extra';
    src: url('/fonts/AXIS-ExtraBold.woff2') format('woff2'),
        url('/fonts/AXIS-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

//Font: Neue Haas Grotesk Display Pro 55 Roman

@font-face {
    font-family: 'NeueHaasGroteskDisp Pro';
    src: url('/fonts/NHaasGroteskDSPro-55Rg.woff2') format('woff2'),
        url('/fonts/NHaasGroteskDSPro-55Rg.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

