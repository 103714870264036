$circle_width : 70px;
$circle_pos : $circle_width - 10px;
$circle_pos2 : calc($circle_width / 2);

.grouplogo {
    width: 0;
    height: 0;
    // margin-bottom: 200px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .item {    
        position: absolute;
        width: $circle_width;
        height: $circle_width;
        top: 50%;
        margin-top: -$circle_pos2;

        
        &.top {
            top: -$circle_pos;
            left: 50%;
            transform: translate(-50%, 0) rotate(90deg);
        }

        &.bottom {
            top: $circle_pos;
            left: 50%;
            transform: translate(-50%, 0) rotate(90deg);
        }

        &.right {
            left: $circle_pos2;   
        }

        &.left {
            right: $circle_pos2; 
        }

        .circle {
            border-radius: 50%;
        }

        .circle_s {
            width: 60%;
            height: 60%;
            border: 6px solid #fff;
            @include objAbsoluteMiddle;
        }

        .circle_b {
            width: 100%;
            height: 100%;
            border: 6px solid #fff;
            @include objAbsoluteMiddle;

            &::before {
                content: "";
                position: absolute;
                top: -18px;
                left: 50%;
                transform: translateX(-50%);
                width: 6px;
                height: 17px;
                background: #fff;
            }
    
            &::after {
                content: "";
                position: absolute;
                bottom: -18px;
                left: 50%;
                transform: translateX(-50%);
                width: 6px;
                height: 17px;
                background: #fff;
            }
        }

    }

    
}


.sliderprocess {
    opacity: .5;
    position: absolute;
    width: $circle_width + 30px;
    height: $circle_width;
    // left: -($circle_width * 2);
    top: 50%;
    transform: translateY(-50%);

    
    &.next {
      left: -($circle_width * 2);
    }

    &.prev {
      left: 0;
    }
}

.group-slidercta {

    &.logoblue {
        z-index: 1;

        .grouplogo .item {

            .circle_s {
              border: 6px solid $color_blue;
            }
    
            .circle_b {
              border: 6px solid $color_blue;
    
              &::before ,&::after {
                background: $color_blue;
              }
            }
          }
    }
}