.cpvisual {
    @include webmain_spacing-bottommain;

    @include selectNone();

    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh;

    @include respond-mobile {
        @include fullheightVH();
    }

    min-height: 700px;

    @include respond-landscapemobile() {
        min-height: auto;
    }


    .bg {
        background-color: #000000;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        pointer-events: none;


        img,
        video {
            opacity: .7;
            width: 100%;
            height: 100%;
        }


        img,
        canvas,
        video {
            object-fit: cover;
            @include objAbsoluteMiddle;
        }


    }

    // INNERS
    .inner {
        z-index: 1;
        width: 100%;
        max-width: 1300px;
        color: #fff;
        text-align: center;
        @include objAbsoluteMiddle;
    }


    .icon-scroll {
        width: 24px;
        height: 56px;
        @include objAbsoluteMiddleX();
        bottom: 20px;

        path {
            fill: #fff;

            &#Path_7,
            &#Path_8 {
                @include respond-tabletportrait() {
                    display: none;
                }

                @include respond-mobile() {
                    display: none;
                }
            }
        }
    }

    ////////////////////
    // RESPONESIVE
    .inner {
        padding: 40px;

        @include respond-landscapemobile() {
            padding: 0;

            br {
                display: none;
            }
        }

        @include respond-to('mb') {
            padding: 5vw;
        }
    }

    h6 {
        @include webmain_font-special;
        @include webmain_text-normaller;
        color: $color_KC_Orange;
    }

    h1 {
        @include webmain_text-biggest;
        @include webmain_font-special;
        margin-bottom: 30px;

        @include respond-tabletportrait() {
            margin-bottom: 6vw;
        }

        @include respond-landscapemobile() {
          margin-bottom: 1vw;
        }
    }

    h5 {
        @include webmain_font-bold;
        @include webmain_text-normaller;

    }

    p {
        @include webmain_font_regular2;
        @include webmain_text-normal;
        max-width: 750px;
        width: 90%;
        margin: auto;
    }

    .cpvisual-content {
        height: 100%;

        .details {
            height: 100%;
            width: 100%;
            max-width: 600px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;

            p {
                color: #fff;
            }
        }
    }


    .inner {

        .content-desc {
            opacity: 0;
            height: 0;
            overflow: hidden;
        }

        .content-detail {
            height: auto;
        }
    }

    //     .content-desc {
    //         display: block;
    //     }

    //     .content-detail {
    //         max-height: 0;
    //     }
    // }
}

.cpvisual--inside {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
    min-height: auto;
}

.cpvisual-video {
    background: none;
    background-color: #cccccc;

    video {
        width: 100%;
        height: 100%;
        @include objAbsoluteMiddle;
        object-fit: cover;
    }
}
