.page.ecosystem {
    .ecosystem-carrousel {
        @include webmain_spacing-topmain;

        // @include webmain_spacing-bottommain;
        .group-button {
            .flickity-prev-next-button {
                top: 15%;
            }
        }



        // team introduction
        .team-introduction {
            color: $color_KC_darkBlue;
            text-align: center;

            h2 {
                @include webmain_text-big;
                @include webmain_font-special;
            }

            p {
                @include webmain_font-regular;
                @include webmain_text-small;
                max-width: 850px;
                margin: 40px auto;

                @include respond-tabletportrait() {
                    margin: 30px auto;
                }

                @include respond-mobile() {
                    margin: 4vw auto;
                }
            }
        }

        //carrousel-label
        .carousel-label {
            // display: flex;
            // justify-content: center;
            width: 100%;
            margin: 0 auto;

            @include respond-tabletlandscape() {
                width: 100%;
            }

            @include respond-tabletportrait() {
                margin: 50px auto 0;
            }

            @include respond-mobile() {
                margin: 10vw auto 0;
            }

            .carousel-label-item {
                position: relative;
                padding: 0 20px 25px;

                @include respond-mobile() {
                    // min-width: 38vw;
                    // min-height: 35vw;
                }

                @include respond-landscapemobile() {
                    min-width: initial;
                    min-height: initial;
                }

                &.active::after {
                    content: "";
                    width: 100%;
                    height: 5px;
                    background-color: $color_KC_Orange;
                    position: absolute;
                    bottom: 0;
                    left: 0;

                    @include respond-mobile() {
                        top: 49vw;
                    }
                }

                .inner {
                    position: relative;
                    width: 164px;
                    height: 164px;
                    margin: 0 auto;

                    @include respond-tabletlandscape() {
                        width: 130px;
                        height: 130px;
                    }

                    @include respond-tabletportrait() {
                        width: 130px;
                        height: 130px;
                    }

                    @include respond-mobile() {
                        width: 30vw;
                        height: 30vw;
                        outline: none;
                    }

                    @include respond-landscapemobile() {
                        min-width: initial;
                        min-height: initial;
                    }

                    .overlay-text-hover {
                        opacity: 0;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background-color: rgba(7, 30, 46, 0.7);
                        border-radius: 50%;
                        text-decoration: none;
                        outline: none;
                        cursor: pointer;
                        @include objAbsoluteMiddle;


                        h4 {
                            @include webmain_text-font16;
                            @include webmain_font-special;
                            line-height: 1;
                            color: #fff;
                            @include objAbsoluteMiddle;
                            text-align: center;
                            margin-bottom: 0;
                            width: 80%;

                            span {
                                @include webmain_text-font12;
                                @include webmain_font-regular;
                                text-transform: capitalize;
                            }
                        }

                        &:hover {
                            opacity: 1;
                            @include animate(0.2s);

                            @include respond-tabletlandscape() {
                                opacity: 0;
                            }
                        }

                        &.active {
                            opacity: 1 !important;
                            @include animate(0.2s);
                        }
                    }

                    .circle-name {
                        background: $color_orange;
                        color: $color_blue;
                        border-radius: 50%;
                        margin: 0 auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        @include webmain_font-special;
                        @include webmain_fontSize_title;
                    }

                    img,
                    .circle-name {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        //carrousel-inner
        .ecosystem-carrousel-inner {
            @include webmain_spacing-bottommain;
            overflow: hidden;

            @include respond-landscapemobile {
                margin-bottom: 5vw;
            }

            .ecosystem-carrousel-content {
                background-color: $color_KC_light-gray;

                .carrousel_img {
                    position: relative;
                    height: 370px;

                    @include respond-tabletportrait() {
                        height: 26vw;
                    }

                    @include respond-mobile() {
                        height: 35vw;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: 0 -75px;

                        @include respond-tabletlandscape() {
                            object-position: 0 0;
                        }
                    }

                    .circle-name {
                        height: 100%;
                        width: 100%;
                        background: $color_orange;
                        color: $color_blue;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include webmain_font-special;
                        @include webmain_fontSize_superbig;
                    }
                }

                .carrousel_text_content {
                    color: $color_KC_darkBlue;
                    padding: 30px;
                    height: 430px;

                    @include respond-desktopsmall() {
                        height: 500px;
                    }

                    @include respond-tabletlandscape() {
                        height: 80vw;
                    }

                    @include respond-tabletportrait() {
                        height: 100vw;
                    }

                    @include respond-mobile() {
                        padding: 7.5vw;
                        height: 120vw;
                    }

                    @include respond-landscapemobile() {
                        height: 90vw;
                    }

                    .inner {
                        display: flex;
                        justify-content: space-between;
                        height: auto;

                        @include respond-tabletlandscape() {
                            flex-direction: column;
                        }

                        .left_content {
                            @include webmain_text-small;
                            @include webmain_font-special;
                            line-height: 1.2;
                            width: 40%;
                            padding: 0 80px;

                            @include respond-desktopsmall() {
                                padding-right: 5vw;
                            }

                            @include respond-tabletlandscape() {
                                width: 53%;
                                padding-right: 0;
                                text-align: left;
                                padding-bottom: 40px;
                                line-height: 1;
                                padding: 0 30px;
                            }

                            @include respond-tabletportrait() {
                                padding-bottom: 2vw;
                                width: 70%;
                                padding: 0;
                            }

                            @include respond-mobile() {
                                padding-bottom: 2vw;
                                width: 63vw;
                            }

                            h5 {
                                @include webmain_text-small;
                                @include webmain_font-special;
                            }
                        }

                        .right_content {
                            width: 100%;
                            line-height: 2;
                            padding: 0 80px;

                            @include respond-tabletlandscape() {
                                width: 100%;
                                line-height: 1.1;
                                padding: 0 30px;
                            }

                            @include respond-tabletportrait() {
                                font-size: 2.5vw;
                                padding: 0;
                            }

                            @include respond-mobile() {
                                font-size: 2.5vw;
                            }

                            span {
                                background: $color_blue;
                                padding: 5px 10px;
                                color: #ffffff;
                                @include webmain_font-special;
                                @include webmain_text-font18;
                            }

                            h2 {
                                @include webmain_font-special;
                                @include webmain_text-font42;
                                margin-top: 10px;
                            }

                            h5 {
                                @include webmain_font-special;
                                @include webmain_text-smallii;
                                color: $color_KC_Orange;
                                padding: 10px 0 15px;

                                @include respond-tabletportrait() {
                                    padding: 2vw 0 3vw;
                                }

                                @include respond-mobile() {
                                    padding: 2vw 0 3vw;
                                }
                            }

                            p {
                                @include webmain_font-regular;
                                @include webmain_text-smallii;
                            }
                        }
                    }
                }
            }

            .flickity-prev-next-button {
                top: 15% !important;
                transform: translateY(0);

                @include respond-tabletlandscape() {
                    top: 11vw !important;
                }

                @include respond-mobile() {
                    top: 15vw !important;
                }
            }
        }
    }

    // advisor partner
    .advisor-partner {
        text-align: center;

        h2 {
            @include webmain_fontSize_title;
            @include webmain_font-special;
            line-height: 1.3;
        }

        .advisor-partner-content {
            .advisor-partner-inner {
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                max-width: 920px;
                margin: 60px auto;

                @include respond-tabletportrait() {
                    margin: 40px auto;
                }

                @include respond-mobile() {
                    margin: 2vw auto;
                }

                .advisor-partner-item {
                    width: 150px;
                    height: 150px;
                    color: $color_KC_darkBlue;
                    text-align: center;
                    position: relative;
                    margin: 15px 5px;
                    border-radius: 50%;
                    background: transparent;

                    @include respond-mobile() {
                        width: 30vw;
                        height: 30vw;
                        margin: 2vw 1vw;
                    }

                    @include respond-landscapemobile {
                        width: 150px;
                        height: 150px;
                    }

                    .inner {
                        width: 100%;
                        height: 100%;
                        position: relative;

                        .overlay-text-hover {
                            opacity: 0;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background-color: rgba(7, 30, 46, 0.7);
                            border-radius: 50%;
                            text-decoration: none;
                            cursor: pointer;

                            h4 {
                                @include webmain_text-smallii;
                                @include webmain_font-special;
                                line-height: 1;
                                color: #fff;
                                @include objAbsoluteMiddle;
                                top: 65%;
                                text-align: center;

                                span {
                                    @include webmain_text-smaller;
                                    @include webmain_font-regular;
                                    text-transform: capitalize;
                                    line-height: 1.8;
                                }
                            }

                            &:hover {
                                opacity: 1;
                                @include animate(0.2s);
                            }
                        }

                        img {
                            width: 80%;
                            height: auto;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    &:last-child {

                        .inner {

                            img {
                                width: 60%;
                            }
                        }
                    }
                }
            }
        }
    }

    // testimonials
    .testimonials {
        @include webmain_spacing-bottommain;

        @include respond-mobile() {
            margin-bottom: 10vw;
        }

        .testimonial-introduction {
            text-align: center;

            h2 {
                @include webmain_fontSize_title;
                @include webmain_font-special;
            }

            p {
                @include webmain_font-regular;
                @include webmain_text-small;
                max-width: 800px;
                margin: 60px auto;

                @include respond-tabletportrait() {
                    margin: 40px auto;
                }

                @include respond-mobile() {
                    margin: 5vw auto;
                }
            }
        }

        .group-testimonial-carousel {
            overflow: hidden;
        }

        .carousel-testimonials {

            .testimonial-item {
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $color_KC_light-gray;
                padding: 100px 50px;
                min-height: 600px;

                @include respond-desktopsmall() {}

                @include respond-tabletlandscape() {
                    flex-direction: column;
                }

                @include respond-tabletportrait() {
                    padding: 50px;
                }

                @include respond-mobile() {
                    padding: 7.5vw;
                    min-height: 0;
                }

                .testimonial-image {
                    width: 40%;
                    text-align: center;


                    @include respond-tabletlandscape() {
                        width: 100%;
                    }

                    @include respond-tabletportrait() {
                        padding: 5vw 0;
                    }

                    img {
                        border-radius: 50%;
                        margin: 0 auto;
                        width: 341px;
                        height: 341px;

                        @include respond-mobile() {
                            width: 60vw;
                            height: 60vw;
                        }
                    }

                    .circle-name {
                        width: 341px;
                        height: 341px;
                        background: $color_orange;
                        color: $color_blue;
                        border-radius: 50%;
                        margin: 0 auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        @include webmain_font-special;
                        @include webmain_fontSize_title;

                        @include respond-mobile() {
                            width: 60vw;
                            height: 60vw;
                        }
                    }
                }

                .testimonial-text {
                    width: 60%;
                    background-color: $color_KC_light-gray;

                    @include respond-tabletlandscape() {
                        width: 100%;
                        padding: 7vw 0;
                        height: 375px;
                    }

                    @include respond-mobile() {
                        height: 85vw;
                    }

                    @include respond-landscapemobile() {
                        padding: 5vw;
                        height: 50vw;
                    }

                    a {

                        img {
                            width: 165px;
                            height: auto;

                            @include respond-mobile() {
                                width: 30vw;
                                height: auto;
                            }
                        }
                    }

                    .detail {
                        display: none;
                        opacity: 0;
                    }
                }


                h3 {
                    @include webmain_fontSize40;
                    @include webmain_font-special;
                    line-height: 1.4;
                    @include animate(.5s);
                }

                h5 {
                    @include webmain_fontSize_medium;
                    @include webmain_font-special;
                    color: $color_KC_Orange;
                }

                p {
                    @include webmain_font-regular;
                    @include webmain_text-small;
                }

                .text {
                    display: block;
                    height: auto;
                    opacity: 1;

                    &.hide {
                        display: none;
                        opacity: 0;
                        margin: 0;
                    }
                }

                .close-detail {
                    opacity: 0;
                    height: 0;
                }

                // &.open-detail {
                //     align-items: flex-start;

                //     .testimonial-image {
                //         width: 40%;
                //         text-align: center;
                //         position: absolute;
                //         top: 50%;
                //         left: 0;
                //         transform: translateY(-50%);


                //         @include respond-tabletlandscape() {
                //             width: 100%;
                //             position: relative;
                //             top: 0;
                //             transform: translateX(0);
                //         }

                //         @include respond-tabletportrait() {
                //             padding: 5vw 0;
                //         }

                //         @include respond-mobile() {
                //             position: relative;
                //             top: 0;
                //             transform: translateX(0);
                //         }

                //         img {
                //             border-radius: 50%;
                //             margin: 0 auto;
                //             width: 60%;
                //         }



                //         h5 {
                //             margin-top: 20px;
                //         }

                //         p {
                //             margin-top: 10px;
                //         }
                //     }

                //     .testimonial-text {
                //         width: 50%;
                //         padding: 50px 180px;
                //         height: 700px;

                //         @include respond-desktopsmall() {
                //             padding: 50px 50px 50px 0;
                //         }

                //         @include respond-tabletlandscape() {
                //             padding: 5vw;
                //             width: 100%;
                //             height: auto;
                //         }

                //         @include respond-tabletportrait() {
                //             padding: 5vw;
                //             height: auto;
                //         }

                //         @include respond-mobile() {
                //             padding: 7.5vw;
                //             height: auto;
                //         }

                //         .detail {
                //             display: block;
                //             @include webmain_text-font15();
                //         }

                //         .inner {
                //             padding: 0 180px 0 50px;
                //             position: absolute;
                //             top: 50%;
                //             transform: translateY(-50%);

                //             @include respond-desktopsmall() {
                //                 padding: 0 180px;
                //             }

                //             @include respond-tabletlandscape() {
                //                 padding: 0 50px;
                //                 position: relative;
                //                 top: 0;
                //                 transform: translateX(0);
                //             }

                //             @include respond-mobile() {
                //                 padding: 0;
                //                 position: relative;
                //                 top: 0;
                //                 transform: translateX(0);
                //             }
                //         }
                //     }

                //     h3 {
                //         @include webmain_text-small();
                //         width: 70%;

                //         @include respond-tabletportrait() {
                //             width: 100%;
                //         }
                //     }

                //     .text {
                //         display: none;

                //         &.hide {
                //             display: block;
                //             padding: 0 80px;

                //             @include respond-tabletlandscape() {
                //                 padding: 0;
                //             }

                //             @include respond-tabletportrait() {
                //                 padding: 0 5vw;
                //             }

                //             @include respond-mobile() {
                //                 padding: 0 5vw;
                //             }
                //         }
                //     }
                // }

            }

            .group-slidercta {

                @include respond-tabletlandscape() {
                    top: 28%;
                }

                @include respond-tabletportrait() {
                    top: 25%;
                }

            }
        }
    }

    // custom cta
    .cta {
        &.cta-none {
            border-radius: 30px;
            border: 2px solid $color_KC_darkBlue;
            color: $color_KC_darkBlue;
            background: transparent;
            padding: 10px 50px;
        }
    }

    .close-detail {
        position: absolute;
        top: 40px;
        right: 50px;
        width: 30px;
        height: 30px;
        overflow: hidden;
        z-index: 9;
        cursor: pointer;

        @include respond-mobile {
            top: 25px;
            right: 15px;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 25px;
            height: 4px;
            background: #071e2e;
            top: 10px;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }

    .group-slidercta .button-text {
        color: #000000;
    }
}

@media (max-width: 1200px) {
    .page.ecosystem .ecosystem-carrousel .carousel-label .carousel-label-item .inner .overlay-text-hover:hover {
        opacity: 0;
    }
}