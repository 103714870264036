.block {
    margin-bottom: 40px;

    &:last-child {
        margin-bottom: 0;
    }

    @include respond-mobile() {
        margin-bottom: 7vw;
    }

    @include respond-landscapemobile() {
        margin-bottom: 5vw;
    }

    .inner {
        display: flex;
        align-items: flex-start;

        @include respond-mobile() {
            padding-bottom: 5vw;
        }

        .block__num {
            @include webmain_fontSize_number;
            @include webmain_font-special;
            color: $color_KC_gray;
            line-height: 0.6;
            margin-right: 30px;
            width: 45px;

            @include respond-tabletportrait() {
                margin-right: 30px;
                width: 28px;
            }

            @include respond-mobile() {
                margin-right: 7vw;
                width: 2.7vw;
            }

            @include respond-landscapemobile() {
                width: 4.5vw;
            }
        }

        .block__text {
            @include webmain_font-regular;
            @include webmain_text-small;
            line-height: 1.5;

            span {
                @include webmain_font-bold;
                display: block;
                margin-bottom: 10px;

                @include respond-mobile() {
                    margin: 5px 0 10px;
                }
            }
        }
    }
}
body.IE {
    .block .inner .block__num {
        margin-right: 90px;
    }
}