.page.home {
    max-height: 100vh;

    .section {
        // height: 100% !important;

        &.section-page.fixfullpage {
            position: fixed;
            top: 0;
            z-index: 9999;
        }

        &-footer {

            .footer {
                padding-top: 0;
            }
        }

        .fp-tableCell {
            // height: 100% !important;
            transform: scale(0.95);
            // @include animate(.5s);

            &.active {
                transform: scale(1);
            }

        }

        p {
            @include webmain_font-regular();
        }

        &-footer {

            .fp-tableCell {
                transform: scale(1);
            }
        }

    }

    .cpvisual {
        margin-bottom: 0;

        .inner {

            .content-desc {
                opacity: 1;
                height: auto;
                overflow: hidden;
            }

            .content-detail {
              height: 0;
              overflow: hidden;
            }

            h6 {
                @include respond-landscapemobile() {
                    font-size: 3vw;
                }
            }

            h1 {
                @include respond-landscapemobile() {
                    font-size: 4vw;
                    margin-bottom: 10px;
                }
            }

            // .content-desc p{
            //   font-size: 2.5vw;
            // }

            .cta {
                margin-top: 15px;
                position: absolute;
                top: 90%;
                left: 50%;
                transform: translateX(-50%);


                @include respond-mobile() {
                    margin-top: 6vw;
                    width: 50vw;
                }

                @include respond-landscapemobile() {
                    width: 30vw;
                }


                @include respond-landscapemobile() {
                    margin-top: 0;
                    font-size: 2vw;
                }


                &.hide {
                    display: none;
                }

                &-oneline {

                    @include respond-mobile() {
                        width: 70vw;
                    }

                    @include respond-landscapemobile() {
                        width: 40vw;
                    }

                }

                &-white {
                    @include respond-landscapemobile() {
                        padding: 1.5vw 2vw;
                        margin-top: 3vw;
                    }
                }
            }

        }
    }

    .footer {

        .custom-svg {

            .hide {
                display: none;
            }
        }
    }

}


#logogrear {
    width: 100%;
    height: 178px;
    position: fixed;
    bottom: 0;
    background: transparent;

    @include respond-desktopsmall() {
        height: 100px;
    }

    @include respond-mobile() {
        height: 25vw;
    }

    @include respond-landscapemobile() {
        height: 13vh;
    }
}

.gear {
    background: url("/images/logo-slider.png") no-repeat;
    background-size: cover;
    width: 178px;
    height: 178px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%) rotate(90deg);

    @include respond-landscapemobile() {
        width: 25vh;
        height: 25vh;
    }

    &.fixed {
        bottom: -90px;

        @include respond-landscapemobile() {
            bottom: -13vh;
        }
    }

    &-footer {
        background: url("/images/logo-slider.png") no-repeat;
        background-size: cover;
        width: 178px;
        height: 178px;
        position: fixed;
        opacity: 0;
        top: -90px;
        left: 50%;
        transform: translateX(-50%) rotate(90deg);

        @include respond-landscapemobile() {
            width: 25vh;
            height: 25vh;
            top: -12vh;
        }
    }
}





/*responsive*/
