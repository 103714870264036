// SPACING
$spacing_main_desktop: 80px;
$spacing_main_mobile: 10vw;

@mixin webmain_spacing-topmain {
    margin-top: $spacing_main_desktop;

    @include respond-to("xs") {
        margin-top: $spacing_main_mobile;
    }
}

@mixin webmain_spacing-bottommain {
    margin-bottom: $spacing_main_desktop;

    @include respond-to("xs") {
        margin-bottom: $spacing_main_mobile;
    }
}

// WEBSITE MAIN
@mixin webmain_font-regular {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-style: normal;
    line-height: 1.2;
}

@mixin webmain_font-bold {
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    line-height: 1.2;
}

@mixin webmain_font-special {
    font-family: "AXIS Extra";
    font-weight: 800;
    font-style: normal;
    line-height: 1.2;
}

@mixin webmain_font_regular2 {

    font-family: 'NeueHaasGroteskDisp Pro';
    font-weight: 500;
    font-style: normal;
    line-height: 1.2;
}

@mixin webmain_fontSize_superbig {
    font-size: 120px;

    @include respond-mobile() {
        font-size: 15vw;
    }

    @include respond-landscapemobile {
        font-size: 5vw;
    }
}

@mixin webmain_fontSize_title {
    font-size: 58px;

    @include respond-desktopsmall() {
        font-size: 40px;
    }

    @include respond-tabletportrait() {
        font-size: 30px;
    }

    @include respond-mobile() {
        font-size: 8vw;
    }

    @include respond-landscapemobile {
        font-size: 5vw;
    }
}

@mixin webmain_text-big() {
    font-size: 53px;

    @include respond-desktopsmall() {
        font-size: 30px;
    }

    @include respond-tabletportrait() {
        font-size: 24px;
    }

    @include respond-mobile() {
        font-size: 5.5vw;
    }

    @include respond-landscapemobile {
        font-size: 2.7vw;
    }
}

@mixin webmain_fontSize48() {
    font-size: 48px;

    @include respond-desktopsmall() {
        font-size: 30px;
    }

    @include respond-tabletportrait() {
        font-size: 28px;
    }

    @include respond-mobile() {
        font-size: 5.5vw;
    }

    @include respond-landscapemobile {
        font-size: 3.5vw;
    }
}

@mixin webmain_text-font42() {
    font-size: 42px;

    @include respond-desktopsmall() {
        font-size: 32px;
    }

    @include respond-tabletportrait() {
        font-size: 22px;
    }

    @include respond-mobile() {
        font-size: 4.5vw;
    }
}

@mixin webmain_fontSize40() {
    font-size: 40px;

    @include respond-desktopsmall() {
        font-size: 30px;
    }

    @include respond-tabletportrait() {
        font-size: 20px;
    }

    @include respond-mobile() {
        font-size: 4.5vw;
    }
}




@mixin webmain_text-normallbig() {
    font-size: 32px;

    @include respond-desktopsmall() {
        font-size: 28px;
    }

    @include respond-tabletportrait() {
        font-size: 24px;
    }

    @include respond-mobile() {
        font-size: 5vw;
    }

    @include respond-landscapemobile() {
        font-size: 3.5vw;
    }
}


@mixin webmain_fontSize_medium() {
    font-size: 30px;

    @include respond-desktopsmall() {
        font-size: 25px;
    }

    @include respond-tabletlandscape() {
        font-size: 20px;
    }

    @include respond-tabletportrait() {
        font-size: 16px;
    }

    @include respond-mobile() {
        font-size: 4vw;
    }

    @include respond-landscapemobile {
        font-size: 3vw;
    }
}

// font size and font familly for  1st text-visual

@mixin webmain_text-normaller {
    font-size: 25px;

    @include respond-tabletportrait() {
        font-size: 22px;
    }

    @include respond-mobile() {
        font-size: 5vw;
    }

    @include respond-landscapemobile() {
        font-size: 3.5vw;
    }
}

// end font size and font familly for  1st text-visual

// font familly for  3rd text-visual
@mixin webmain_text-normal {
    font-size: 23px;

    @include respond-desktopsmall() {
        font-size: 19px;
    }

    @include respond-tabletlandscape() {
        font-size: 16px;
    }

    @include respond-mobile() {

        font-size: 4vw;
    }

    @include respond-landscapemobile() {
        font-size: 2.2vw;
    }
}

// font familly for  3rd text-visual

//font size for number block
@mixin webmain_fontSize_number {
    font-size: 100px;

    @include respond-desktopsmall() {
        font-size: 80px;
    }

    @include respond-tabletlandscape() {
        font-size: 60px;
    }

    @include respond-mobile() {

        font-size: 10.5vw;
    }
}

//end of font size for number block

@mixin webmain_text-small {
    font-size: 20px;

    @include respond-desktopsmall() {
        font-size: 17px;
    }

    @include respond-tabletlandscape() {
        font-size: 18px;
    }

    @include respond-mobile() {

        font-size: 4vw;
    }

    @include respond-landscapemobile() {
        font-size: 3vw;
    }
}

@mixin webmain_text-font24 {
    font-size: 24px;

    @include respond-tabletportrait() {
        font-size: 18px;
    }

    @include respond-mobile() {
        font-size: 3vw;
    }
}


@mixin webmain_text-font18 {
    font-size: 18px;

    @include respond-mobile() {
        font-size: 3vw;
    }
}

@mixin webmain_text-smallii {
    font-size: 16px;

    @include respond-tabletportrait() {
        font-size: 2.5vw;
    }

    @include respond-mobile() {
        font-size: 3vw;
    }

    @include respond-landscapemobile() {
        font-size: 2vw;
    }
}

@mixin webmain_text-font15 {
    font-size: 15px;

    @include respond-mobile() {
        font-size: 4vw;
    }

    @include respond-landscapemobile() {
        font-size: 2.5vw;
    }
}

@mixin webmain_text-font16 {
    font-size: 16px;

    @include respond-desktopsmall() {
      font-size: 12px;
    }
}

@mixin webmain_text-font12 {
    font-size: 12px;

    @include respond-desktopsmall() {
      font-size: 10px;
    }
}


@mixin webmain_text-smaller {
    font-size: 12px;

    @include respond-tabletlandscape() {
        font-size: 2vw;
    }

    @include respond-mobile() {

        font-size: 2.5vw;
    }
}

// font size and font familly for  2nd text-visual
@mixin webmain_text-biggest {
    @include webmain_font-special;
    font-size: 88px;
    line-height: 1;

    @include respond-desktopsmall {
        font-size: 75px;
    }

    @include respond-tabletlandscape {
        font-size: 50px;
    }

    @include respond-mobile {
        font-size: 10vw;
    }

    @include respond-landscapemobile() {
        font-size: 4.5vw;
    }
}





// end of font size and font familly for  2nd text-visual

@mixin webmain_text-mediumlarge {
    font-size: 40px;

    @include respond-desktopsmall {
        font-size: 35px;
    }

    @include respond-tabletlandscape {
        font-size: 30px;
    }

    @include respond-tabletportrait() {
        font-size: 25px;
    }

    @include respond-mobile {
        font-size: 5vw;
    }

    @include respond-landscapemobile() {
        font-size: 3.5vw;
    }
}

// Shape Circle
@mixin circle {
    &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        background: $color_text;
        border-radius: 50%;

        @include respond-mobile {
            width: 3vw;
            height: 3vw;
        }
    }
}

// FONTS FAMILY
body,
p {
    @include webmain_font-regular;
}

h1,
h2,
h3,
h4,
h5,
label {
    @include webmain_font-bold;
}

// COLOR
.cblue {
    color: $color_blue;
}

.cpink {
    color: $color_pink;
}

.cyellow {
    color: $color_yellow;
}

.cgreen {
    color: $color_green;
}

.cpurple {
    color: $color_text;
}

.cwhite {
    color: $color_white;
}


.corange {
    color: $color_KC_Orange;
}


// FONTS SIZE
h6 {
    font-size: 14px;
}

body {
    @include webmain_text-smallii;
}

p,
h1 span,
h2 span {
    @include webmain_text-normaller;
}

.textnormal,
.cpvisual p.textnormal {
    @include webmain_text-normal;
}

h3,
h4,
h5 {
    font-size: 30px;
}

h1,
h2,
.title {
    @include webmain_text-biggest;
}

.spacing-b {
    @include webmain_spacing-bottommain();
}

//hide class

.mobile-show {
    display: none;

    @include respond-tabletportrait() {
        display: inline-block;
    }

    @include respond-mobile {
        display: inline-block;
    }
}

.mobile-hide {
    display: inline-block;

    @include respond-tabletportrait() {
        display: none;
    }

    @include respond-mobile {
        display: none;
    }
}

//FOR HOVER
@mixin hoverpublic {
    cursor: pointer;
    @include animate(0.2s);
}

.Desktop {
    // svg
    // .logo:hover {
    //   @include hoverpublic();
    //   path {
    //     fill: $color_pink;
    //   }
    // }

    .iconmenu:hover i,
    .iconmenu:hover:before,
    .iconmenu:hover:after {
        @include hoverpublic();
        // background-color: $color_pink;
    }
}
