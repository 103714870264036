section.about {


    .main {
        @include webmain_spacing-topmain;
        @include webmain_spacing-bottommain;

        .content {
            background-color: $color_KC_light-gray;
        }

        .bg {
            position: relative;

            img {
                height: 85%;
                width: 100%;
                object-fit: cover;

                @include respond-tabletportrait {
                    height: 60%;
                }

                @include respond-mobile {
                    height: 120vw;
                }

                @include respond-landscapemobile() {
                    height: 100%
                }
            }

            .inner {
                @include objAbsoluteMiddle;
                width: 66%;
                text-align: center;
                color: #fff;

                @include respond-tabletlandscape {
                    width: 90%;
                }

                @include respond-tabletportrait {}


                @include respond-mobile {
                    width: 100%;
                    padding: 0 5vw;
                }
            }

            h1 {
                padding-bottom: 10px;

                @include respond-mobile {
                    padding-bottom: 1vw;
                }
            }

            h1,
            span {
                @include webmain_text-big;
                @include webmain_font-special;
            }

            p {
                @include webmain_text-normal;
                @include webmain_font-special;
            }
        }

        .group-para {
            display: flex;
            justify-content: space-between;
            width: 75%;
            margin: auto;
            margin-top: 60px;
            margin-bottom: 60px;

            @include respond-tabletportrait {
                flex-direction: column;
                // padding: 0;
                margin-top: 20px;
                margin-bottom: 20px;

            }

            @include respond-mobile {
                margin-top: 5vw;
                margin-bottom: 5vw;
                width: 95%;

            }

            div {
                @include webmain_text-small;
                @include webmain_font-regular;
                // width: 45%;
                width: 30%;

                @include respond-tabletportrait {
                    width: 100%;
                    padding: 0 10px 30px;
                }

                @include respond-mobile {
                    padding: 5.5vw;
                }

                @include respond-landscapemobile() {
                    padding: 0 3vw 3vw;
                }

                span {
                    @include webmain_font-bold;
                }
            }
        }

        .group-title {
            @include webmain_font-special;
            @include webmain_text-normal;
            text-align: center;
            padding-bottom: 20px;
            padding-top: 30px;
            width: 90%;
            margin: 0 auto;
        }

        .group-subtitle {
            @include webmain_spacing-bottommain;
            @include webmain_text-normallbig;
            @include webmain_font-special;
            padding: 0 60px 0px;
            text-align: center;
            line-height: 1.2;
            width: 75%;
            margin: auto;

            @include respond-desktopsmall() {
                width: 90%;
            }

            @include respond-tabletportrait {
                padding: 0 10px 30px;
                margin-bottom: 0;
                width: 90%;
            }

            @include respond-mobile {
                width: 100%;
                padding: 0 5vw 10vw;
            }
        }
    }
}
