@import "~bootstrap/scss/bootstrap";
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.css";

@import "bootstrap/breakpoints";
@import 'variables';

@import 'lib/jquery.fullPage';


@import 'public';
@import 'font';
@import 'controls';
@import 'style';
@import 'header';
@import 'footer';
@import 'sliders';

@import 'components/logo';
@import 'components/visuals';
@import 'components/block';
@import 'components/cookie';
@import 'components/popup';

@import 'pages';
@import 'page/home';
@import 'page/investmentmodel';
@import 'page/about';
@import 'page/strategicoutlook';
@import 'page/our-approach';
@import 'page/our-portfolio';
@import 'page/ourecosystem';




