
section.page {
  // height: 100vh;
  // background-color: $color-blue;
 
    
    @include respond-mobile {
      // @include fullheightVH();
    }
    
}

.mobile-hide {

  @include respond-mobile() {
    display: none;
  }
}