.iconmenu {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 42px;
    height: 26px;
    cursor: pointer;
    // @include animate(.2s);
    // transition: linear;

    i,
    &:before {
        height: 7px;
        background: #fff;
        content: '';
        position: absolute;
        @include objAbsoluteMiddleX;
        width: 100%;
        transition: .5s ease;

        @include  respond-landscapemobile(){
            height: 6px;
            left: 90%;
            width: 60%;
        }
    }

      

    i {
        margin-top: 18px;

        @include respond-mobile {
            margin-top: 20px;
        }
    }


    // Open-Menu//
    &.open {
        i {
            transform: translate3d(-20px, 10px, 0) rotate(45deg);
            transition: .5s linear;
            margin-top: 0;
        }

        &:before {
            transform: translate3d(-20px, 10px, 0) rotate(-45deg);
            transition: .5s linear;
        }

    }

    @include respond-mobile {
        width: 8vw;
    }
}

header {
    position: fixed;
    width: 100%;
    z-index: 999;
    padding: 10px 0;


    .backbutton {
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        height: 100%;
        padding: 0;
        cursor: pointer;
        align-items: center;
        display: flex;
        

        @include respond-mobile() {
            height: auto;
            top: 50%;
            transform: translateY(-50%);
        }

        &.hide {
            display: none;
        }

        svg {
            cursor: pointer;
            width: 40px;
            margin-right: 10px;

            path {
                fill: #ffffff;
            }
        }

        label {
            color: #fff;
            padding: 0;
            margin: 0;
            @include webmain_font-regular;
            font-size: 25px;
            cursor: pointer;

            @include respond-mobile() {
                display: none;
            }
        }
    }

    .inner {
        position: relative;
        margin: 0;
        display: flex;
        justify-content: space-between;
        height: 77px;

        @include respond-tabletportrait {
            height: 12vw;
        }

        @include respond-mobile() {
            height: 23vw;
        }

        .logo {
            width: 210px;
            height: 83px;
            margin: 0 auto;
            @include animate(.2s);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            @include respond-landscapemobile(){
                width: 190px;
                height: 70px;
            }

            &-item {
                width: 100%;
                height: 100%;
            }
        }
    }

    .sticky {
        background: #000;
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        top: 0px;
        // transition: .1s ease;
        // @include animate(.2s);

        // &.sticky {
        //   opacity: .5;
        // }

        &.sticky-transparent {
            background-color: transparent;
        }
    }

    &.hidelogo {

        .logo {
            opacity: 0;
        }
    }

    &.fixed_header {
        

        .inner {

            @include respond-tabletportrait {
                height: 12vw;
            }
            
            @include respond-mobile() {
                height: 23vw;
            }
            
        }

        .sticky {
            background: #ffffff;
            opacity: 1;
        }

        .backbutton {


            label {
                color: $color-blue;
            }
        }


        .inner {

            .logo {
                
                transform: translate(-50%, -50%) scale(.8);

                @include respond-mobile() {
                    // margin-top: -3vw;
                }

                svg {

                    path.cls-2 {
                        fill: $color_blue;
                    }
                }
            }

            .iconmenu i,
            .iconmenu:before {
                background: $color-blue;
            }

            svg.chevron-left {

                path {
                    fill: $color_blue;
                }
            }
        }
    }

    &.open {

        .backbutton{
            display: none;
        }

        .logo {
            opacity: 1;
        }

        .sticky {
            background: transparent;
            opacity: 1;
        }


        .backbutton label {
            color: #ffffff;
        }


        .inner {

            .logo {
                // width: 140px;
                // height: 60px;
                transform: translate(-50%, -50%) scale(.8);            

                svg {

                    path.cls-2 {
                        fill: #ffffff;
                    }
                }
            }

            .iconmenu i,
            .iconmenu:before {
                background: #ffffff;
            }

            svg.chevron-left {

                path {
                    fill: #ffffff;
                }
            }
        }
    }

}


.menugroup {
    display: none;
    color: #ffffff;
    position: fixed;
    width: 100%;
    height: 100vh;

    @include respond-mobile {
        @include fullheightVH();
    }

    top: 0;
    left: 0;
    background: $color-blue;
    z-index: 99;

    .container {
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative;
        height: 100%;

        @include respond-desktopsmall {
            width: 90%;
            max-width: none;
        }

    }

    .menulist {
        width: 950px;
        margin: 0 auto;
        text-align: center;
        @include objAbsoluteMiddle();
        
        @include respond-mobile() {
            width: 85vw;
        }
        

        .item {
            @include webmain_font-special;
            @include webmain_text-big;
            display: block;
            position: relative;
            color: #ffffff;
            text-align: center;
            margin-bottom: 20px;
            top: 50px;
            opacity: 0;

            &.item-orange {
                color: $color_KC_Orange;
            }

            @include respond-mobile() {
                padding: 2vw 0;
            }
            @include respond-landscapemobile(){
                padding: 1vw 0;
                font-size: 2.5vw;
            }

            &:hover {
                color: rgba(230, 120, 80, .9);
                text-decoration: none;
            }

            &.active {
                @include webmain_font-bold;
            }

            @include respond-mobile {
                margin-bottom: 0;
            }
        }

        .legal {

            margin-top: 30px;
            @include webmain_font-special;
            @include webmain_text-smallii;

            a {
                color: #ffffff;

                &:hover {
                    color: $color_KC_Orange;
                    text-decoration: none;
                }
            }
        }
    }

}

.page-index {

    header {
        padding: 35px 0 0;
    }
}

.page-ajax {

    header {
        padding: 10px 0;
    }
}