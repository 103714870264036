
.slick-slide {
  height: auto;
  position: relative;
  z-index: 1;
}


.carousel-cell {
  position: relative;
}

.loading {

  .carousel-cell {
  
    &:not(:first-child){
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      top: 0;
      left: 0;
    }

    &.testimonial-item {

      &:not(:first-child){
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        top: 0;
        left: 0;
      }
    }
  }
}

.controls {
  position: absolute;
  z-index: 1;
  height: 20px;
  width: 40px;
  top: 20px;
  left: 10px;
  cursor: pointer;

  .control  {
    height: 100%;  
    width: auto;

    path {
      fill: #ffffff;
    }

    @include objAbsoluteMiddle;

    &.pause {
      display: none;
    }

    &.play {
      display: block;
    }
  }

  &.playing {
    .pause {
      display: block;
    }

    .play {
      display: none;
    }
  }

  &-blue {
    display: none;

    .control path {
        fill: $color_blue;
    }
  }
}

.group-slidercta {
  left: 70px;
  @include objAbsoluteMiddleY();

  @include respond-tabletlandscape {
    transform: translate(0, -50%) scale(.8);
  }

  @include respond-mobile {
    transform: translate(0, -50%) scale(.55);
  }

  @include respond-landscapemobile() {
    transform: translate(0, -50%) scale(.8);
  }
}

.sliderprocess {
  cursor: pointer;
}

.group-slidercta {


  .button-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    text-transform: uppercase;
    white-space: nowrap;
    @include webmain_fontSize_title();
    @include webmain_font-special();

    opacity: 0;

    &-next {
      right: 140px;
    }

    &-prev {
      left: 140px;
    }
  }
}


.slick-slide {

  &:first-child {
    
    .active-line {
      width: 100%;
      display: block;
    }
  }

  .line, .active-line {  
    width: 0px;
    height: 5px;
    background: $color_KC_Orange;
    position: absolute;
    top: 0;
    left: 0;
  }

    .active-line {
      width: 100%;
      display: none;

     
    }

  .carousel-label-item.white {

    .line, .active-line {
      top: auto;
      bottom: 0;
    }
  }

  &.slick-current {
  
    .carousel-label-item, .block-content {
        background-color: #cfd3d7 !important;
    
        /*  &:after {
          width: 90px;
        } */

        &.white {
          background-color: #fff !important;
        }
      }
  }
}

        



#kharisslider_asNavFor_ourecosystem_02 .slick-list {
  overflow: initial;
  // margin-left: 50%;
  // width: 50%;

  // @include respond-tabletlandscape() {
  //   width: 100%;
  //   margin-left: 0;
  // }

  .line {
    top: 1px;
  }
}

