// custom popup//
.group-popup{
    display: none;
    @include objAbsoluteMiddle;
    position: fixed;
    width: 90%;
    height: 80%;
    background-color: $color_KC_gray;
    padding: 100px 50px;
    z-index: 9999999999;

    p {
        @include webmain_text-small;
    }

    h4 {
        @include webmain_text-normaller;
    }

    .inner {
        display: flex;
        width: 100%;
        height: 100%;
    }
  
    .group-left {
      width: 50%;
      height: 100%;
      text-align: center;
  
      img {
          width: 350px;
          height: 350px;
          border-radius: 50%;
          margin-bottom: 20px;
  
      }
  
    }
    .group-right {
        width: 50%;
        height: 100%;
    }

    // button close//
    .xclose {
        cursor: pointer;
        right: 25px;
        top: 25px;
        width: 30px;
        height: 30px;
        position: fixed;
        z-index: 1;
        margin-right: 10px;

        i {
            width: 100%;
            height: 100%;
            opacity: .9;

          &:hover {
            opacity: 1;
          }
          &:before, &:after {
            position: absolute;
            left: 15px;
            content: ' ';
            height: 90%;
            width: 3px;
            background-color: #000;
          }
          &:before {
            transform: rotate(45deg);
          }
          &:after {
            transform: rotate(-45deg);
          }
    }
    }
  } 