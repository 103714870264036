.portfolio {

    .portfolio-content {
        @include webmain_spacing-topmain;
    }

    .portfolio-carrousel {
        position: relative;

        .container {

            @include respond-mobile() {
                padding: 0;
            }
        }

        // .container-first {

        //     @include respond-tabletportrait() {
        //         max-width: 100%;
        //         padding: 0;
        //     }
        // }


        .container-second {
           

            .block-carousel .block-content {
                
                @include respond-tabletlandscape() {
                    padding-top: 2vw;
                }

                @include respond-mobile() {
                    padding-top: 5vw;
                }

                .content_text {
                    height: 160px;

                    @include respond-desktopsmall() {
                        height: 330px;
                    }

                    @include respond-tabletlandscape() {
                        height: 250px;
                    }

                    @include respond-tabletportrait() {
                        height: 45vw;
                    }

                    @include respond-mobile() {
                        height: 50vw;
                    }

                    @include respond-landscapemobile() {
                        height: 65vw;
                    }

                }

                img {

                    @include respond-mobile() {
                        margin-bottom: 5vw;
                    }

                    @include respond-landscapemobile() {
                        margin-bottom: 2vw;
                    }
                }
            }
        }

        .container-third {
            
            .block-carousel .block-content {
                
                @include respond-tabletlandscape() {
                    padding-top: 5vw;
                }

                .content_text {
                    height: 160px;

                    @include respond-desktopsmall() {
                        height: 330px;
                    }

                    @include respond-tabletportrait() {
                        height: 55vw;
                    }

                    @include respond-mobile() {
                        height: 65vw;
                    }

                    @include respond-landscapemobile() {
                        height: 65vw;
                    }
                }

                .content_img {
                    width: 100%;
                    height: 100px;
                    position: relative;
                    margin-bottom: 20px;

                    @include respond-mobile() {
                        height: 20vw;
                        margin-bottom: 2vw;
                    }

                    .logo {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        

                        &1 {
                            width: auto;
                            height: 100%;
                        }

                        &2 {
                            width: auto;
                            height: 70%;

                            @include respond-mobile() {
                                height: 50%;
                            }
                        }

                        &3 {
                            width: auto;
                            height: 50%;
                        }

                        &8 {
                            width: auto;
                            height: 80%;
                            left: -25px;

                            @include respond-mobile() {
                                left: -4vw;
                            }
                        }
                    }
                }
            }
        }
    }

    .carousel {
        outline: none;

        .img-inner {
            width: 100%;
            height: 400px;

            @include respond-tabletportrait() {
                height: 30vw;
            }

            @include respond-mobile() {
                height: 45vw;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &.loading {}
    }

    .block-carousel {
        // display: flex;
        width: 100%;
        margin-bottom: 125px;

        @include respond-mobile() {
            margin-bottom: 10vw;
        }

        .carousel-cell {
            width: 40%;
            margin: 0;
            overflow: hidden;

            @include respond-tabletlandscape() {
                width: 100%;
            }

            &:hover {
                background: #cfd3d7;
            }

            &.active {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    top: -35px;
                    left: 75px;
                    width: 0;
                    height: 0;
                    border-left: 32px solid transparent;
                    border-right: 32px solid transparent;
                    border-bottom: 35px solid #e6e8ea;
                }

                &:hover {
                    background: #e6e8ea;
                }
            }
        }

        .block-content {
            @include webmain_font-regular();
            @include webmain_text-smallii();
            background: #e6e8ea;
            padding: 3.5% 8% 12%;
            cursor: pointer;

            &:hover {
                background: #dddddd;
            }

            .content_text {
                height: 250px;

                @include respond-desktopsmall() {
                    height: 305px;
                }

                @include respond-tabletportrait() {
                    height: 70vw;
                }

                @include respond-mobile {
                    height: 70vw;
                }

                @include respond-landscapemobile() {
                   height: 85vw;
                    font-size: 3vw;
                }
            }

            @include respond-tabletlandscape() {
                padding: 5vw;
                min-width: initial;
            }

            @include respond-mobile() {
                padding: 0 10vw 10vw;
            }


            img {
                width: auto;
                height: 100px;
                margin-bottom: 20px;

                @include respond-mobile() {
                    height: 20vw;
                    margin-bottom: 0;
                }

                @include respond-landscapemobile() {
                    height: 15vw;
                }
            }
        }
    }

    .header-carousel {
        background: #e6e8ea;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 90px 90px 45px;

        @include respond-tabletlandscape() {
            flex-direction: column;
        }

        @include respond-tabletportrait() {
            padding: 5vw;
        }

        @include respond-mobile() {
            padding: 12vw 10vw 10vw;
        }

        @include respond-landscapemobile() {
            padding: 5vw;
        }

        .logo {
            width: 40%;

            @include respond-tabletlandscape() {
                width: 100%;
            }

            img {
                width: 325px;
                height: 104px;

                @include respond-desktopsmall() {
                    width: 20vw;
                    height: 6vw;
                }

                @include respond-mobile() {
                    width: 45vw;
                    height: 14vw;
                }

                @include respond-landscapemobile() {
                    width: 28vw;
                    height: 10vw;
                }
            }
        }

        .content {
            width: 60%;
            @include webmain_font-regular();
            @include webmain_text-small();

            @include respond-tabletlandscape() {
                width: 100%;
                margin-top: 5vw;
            }

            h1 {
                @include webmain_font-regular();
                @include webmain_text-small();
                font-weight: bold;
            }
        }

        &-anglehub {

            .logo {

                img {
                    width: 150px;
                    height: auto;

                    @include respond-mobile() {
                        height: auto;
                    }
                }
            }
        }
    }
}
