.page.ourapproach {


    // carrousel
    .approach-carrousel {
        @include webmain_spacing-topmain;
        @include webmain_spacing-bottommain;


        .container {

            @include respond-mobile() {
                padding: 0;
            }
        }

        //carrousel-label
        .carousel-label {
            /* 
          width:100%;
          display: flex;
          position: relative;
          overflow: hidden;
           */

            .carousel-label-item {
                // min-width: 280px;
                width: 25%;
                height: 120px;
                background-color: $color_KC_light-gray;
                color: $color_KC_darkBlue;
                text-align: center;
                position: relative;
                overflow: hidden;
                cursor: pointer;

                &.active {
                    background-color: #cfd3d7;

                    &:before {
                        content: "";
                        width: 0;
                        height: 0;
                        position: absolute;
                        bottom: -25px;
                        left: 42%;
                        border-left: 25px solid transparent;
                        border-right: 25px solid transparent;
                        border-top: 25px solid #cfd3d7;
                    }
                }

                @include respond-tabletportrait() {
                    height: 100px;

                }

                @include respond-mobile() {
                    height: 15vw;
                }


                h4 {
                    @include objAbsoluteMiddle();
                    @include webmain_text-font24;
                    @include webmain_font-special;
                    text-transform: uppercase;
                    width: 90%;

                    @include respond-desktopsmall() {
                        width: 80%;
                        font-size: 20px;
                    }

                    @include respond-tabletlandscape() {
                        width: 80%;

                    }

                    @include respond-tabletportrait() {
                        width: 95%;

                    }

                    @include respond-mobile() {
                        width: 95%;
                    }

                }

                &:hover {

                    background-color: $color_KC_gray;

                }


            }

            // &::-webkit-scrollbar {
            //   display: none;
            // }


        }




        //carrousel-inner
        .approach-carrousel-content {
            position: relative;

            @include respond-tabletportrait() {
                height: 100%;
                background: #e6e8ea;
            }

            @include respond-mobile() {
                height: 175vw;
                background: #e6e8ea;
            }

            @include respond-landscapemobile() {
                height: 140vw;
            }

            .mobile_content {
                display: none;

                @include respond-tabletportrait() {
                    display: block;
                    @include webmain_font-regular;
                    font-size: 17px;
                    position: absolute;
                    top: 360px;
                }

                @include respond-mobile() {
                    display: block;
                    @include webmain_font-regular;
                    font-size: 3.5vw;
                    position: absolute;
                    top: 60vw;
                }

                @include respond-landscapemobile() {
                    top: 50vw;
                    font-size: 3vw;
                }
            }

            .carrousel_img {

                img {
                    width: 100%;
                    height: 650px;
                    object-fit: cover;

                    @include respond-tabletlandscape() {
                        max-height: 450px;
                    }

                    @include respond-mobile() {
                        max-height: 65vw;
                    }

                    @include respond-landscapemobile() {
                        max-height: 45vw;
                    }
                }
            }

            .carrousel_text_content {
                @include objAbsoluteMiddle;
                width: 80%;

                @include respond-tabletportrait {
                    top: 24%;
                }

                @include respond-mobile {
                    top: 20%;
                }

                @include respond-landscapemobile() {
                    top: 15%;
                }

                .inner {
                    display: flex;
                    align-items: center;
                    height: auto;

                    @include respond-tabletlandscape() {
                        flex-direction: column;
                    }

                    @include respond-tabletportrait() {
                      height: 145px;
                      position: relative;
                    }

                    @include respond-mobile() {
                        height: 38vw;
                        position: relative;
                    }

                    .left_content {
                        @include webmain_text-mediumlarge;
                        @include webmain_font-special;
                        color: #ffffff;
                        width: 50%;
                        padding-right: 100px;

                        @include respond-desktopsmall() {
                            padding-right: 5vw;
                        }

                        @include respond-tabletlandscape() {
                            width: 100%;
                            padding-right: 0;
                            text-align: center;
                            padding-bottom: 40px;
                            //  line-height: 1.2;
                        }

                        @include respond-tabletportrait() {
                            padding-bottom: 2vw;
                            font-size: 25px;
                        }

                        @include respond-mobile() {
                            padding-bottom: 2vw;
                            font-size: 6vw;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }

                        @include respond-landscapemobile(){
                            font-size: 4vw;
                        }
                    }

                    .right_content {
                        @include webmain_text-font18;
                        @include webmain_font-regular;
                        color: #ffffff;
                        width: 50%;
                        line-height: 1.6;

                        @include respond-tabletlandscape() {
                            width: 100%;
                            line-height: 1.1;

                        }

                        @media (max-width: 1200px) {
                            font-size: 14px;
                        }

                        @include respond-tabletportrait() {
                            font-size: 2.4vw;
                            display: none;
                        }

                        @include respond-mobile() {
                            font-size: 2.5vw;
                            display: none;
                        }



                    }

                }



            }

        }

        .group-slidercta {

            @include respond-tabletportrait() {
                top: 24%;
            }

            @include respond-mobile() {
                top: 20%;
            }

            @include respond-landscapemobile() {
                top: 15%;
            }
        }

    }

    // Kharis model

    .governance {
        .governance__head {
            text-align: center;

            h3 {
                @include webmain_text-mediumlarge;
                @include webmain_font-special();
                color: $color_KC_darkBlue;
                padding-bottom: 60px;

                @include respond-tabletportrait() {
                    padding-bottom: 10px;
                }

                @include respond-mobile() {
                    padding-bottom: 0;
                }

                span {
                    color: $color_KC_Orange;
                }
            }

        }

        .governance__content {
            max-width: 1200px;
            margin: 80px auto;
            color: $color_KC_darkBlue;

            @include respond-mobile() {
                margin: 10vw auto;
            }

            .block .inner {

                .block__text-title {
                    font-weight: bold;
                }
            }
        }
    }

}
