.cta {
  cursor: pointer;
  @include webmain_text-small;
  @include webmain_font-regular;

  padding: 30px 70px;
  background-color: $color_pink;
  color: #ffffff;
  display: inline-block;

  @include respond-desktopsmall() {
    padding: 20px 60px;
  }


  @include respond-tabletportrait() {
    padding: 15px 50px;
  }

  @include respond-mobile() {
    padding: 3vw 8vw;
  }

  &:hover {
    color: #ffffff;
    text-decoration: none;
  }

  &-white {
    background: transparent;
    border: 2px solid #ffffff;
    border-radius: 30px;
    padding: 15px 40px;

    @include respond-mobile {
      padding: 2vw 6vw
    }

    &:hover {
      background: #ffffff;
      color: #000000;
    }
  }

  //   $colors:  #020202,#4f7ef3,#07cfb6,#ff8dac,#fdcc11,#ff4074,#380067,#fdcc11;

  //   @for $i from 0 through 4 {

  //     &-#{$i} {
  //       background: nth($colors, random(length($colors)));
  //     }
  //   }

}

.center-me {
  @include respond-tabletlandscape() {
    margin: 0 auto;
  }
}

.pop-up-only {
  display: none;
}

.popup {
  .pop-up-only {
    display: inline-block;
  }
}

.custom-zindex {
  z-index: 1;
}

//Custom Carousel

.main-carousel {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  opacity: 0;
  pointer-events: none;
}


